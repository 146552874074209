import React, {useEffect, useRef, useState, useMemo} from "react";
import localforage from "localforage";
// import axios from 'axios';
import {
    Avatar,
    Badge,
    Input,
    List,
    Space,
    Menu,
    Dropdown,
    Typography,
    Button,
    Tag,
} from "@arco-design/web-react";
import {
    getWxChatsApi,
    getWxUsersApi,
} from "../../../api/normalApi";
import UserChat from "./UserChat";
import {getThreadMessages} from "../../../api/openaiAPI";
import {IconPlus} from "@arco-design/web-react/icon";
import UserManagement from "../Modal/UserManagementModal";
import NotificationSettingModal from '../Modal/NotificationSettingModal';


const {Search: InputSearch} = Input;

const ChatList = ({currentWxAccount, setThreadId, assistant}) => {
    const [chatList, setChatList] = useState([]); // 存储用户数据
    // const [messages, setMessages] = useState([]); // 当前聊天的消息
    // const [imageUrls, setImageUrls] = useState({}); // 存储图片 URL
    const [currentWxUser, setCurrentWxUser] = useState(null);
    const [timestampList, setTimestampList] = useState([]); //每条最后消息的时间戳
    const wxUserUnreadList = useRef([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [manageVisible, setManageVisible] = useState(false);
    const [currentAutoReply, setCurrentAutoReply] = useState(false);
    const [manualList, setManualList] = useState([]); // 存储手动回复的消息列表
    const isFetchingUsers = useRef(false);
    const [notificationVisible, setNotificationVisible] = useState(false);

    // 加载选中的聊天线程
    useEffect(() => {
        const initCurrentWxUser = async () => {
            try {
                const storedUser = await localforage.getItem(
                    `${currentWxAccount.wx_id}-currentWxUser`,
                );
                setCurrentWxUser(storedUser);

                if (storedUser) {
                    setCurrentAutoReply(storedUser.auto_reply);
                    setThreadId(storedUser.thread_id);
                }
            } catch (error) {
                console.error("Error retrieving currentWxUser:", error);
            }
        };

        initCurrentWxUser().then(r => {});
        // eslint-disable-next-line
    }, [currentWxAccount]);


    // 每10秒更新一次用户列表
    useEffect(() => {
        const fetchUsersWithCheck = async () => {
            if (isFetchingUsers.current) return;

            try {
                isFetchingUsers.current = true;
                const users = await fetchUsers();
                if (users.length > 0) {
                    await fetchUsersStatus(
                        users,
                        currentWxUser,
                        setManualList,
                    );
                }
            } finally {
                isFetchingUsers.current = false;
            }
        };

        if (currentWxAccount) {
            fetchUsersWithCheck();
        }

        const intervalId = setInterval(fetchUsersWithCheck, 10000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [currentWxAccount]);

    // 将 useMemo 移到这里，组件顶部的其他 hooks 之后
    const sortedChatList = useMemo(() => [...chatList].sort((a, b) => {
        const aIsManual = manualList.some((manual) => manual.threadId === a.thread_id);
        const bIsManual = manualList.some((manual) => manual.threadId === b.thread_id);

        if (aIsManual && !bIsManual) return -1;
        if (!aIsManual && bIsManual) return 1;

        const aTimestamp = timestampList[a.thread_id] || 0;
        const bTimestamp = timestampList[b.thread_id] || 0;
        return bTimestamp - aTimestamp;
    }), [chatList, manualList, timestampList]);

    // 添加排序后的未读消息列表
    const sortedUnreadList = useMemo(() =>
        sortedChatList.map(item =>
            wxUserUnreadList.current[chatList.findIndex(x => x.thread_id === item.thread_id)]
        ), [sortedChatList, chatList]);

    // 获取线程消息
    const getMessageList = async (threadId, num = 10) => {
        try {
            if (!threadId) return null;
            return await getThreadMessages(threadId, num);
        } catch (e) {
            console.error(`Error fetching messages for thread_id ${threadId}:`, e);
            return null;
        }
    };

    //找到未读的第一条消息
    const findFirstReadTimeMessage = (messages) => {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].metadata && messages[i].metadata.readTime) {
                return i; // 返回第一个匹配的索引
            }
        }
        return -1; // 如果没有找到，返回 -1
    };

    const findManualReplyMessage = (messages) => {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].metadata && messages[i].metadata.manual === "true") {
                return {index: i, message: messages[i]}; // 返回第一个匹配的索引
            }
        }
        return {index: -1, message: null}; // 如果没有找到，返回 -1
    };

    // 获取对话列表
    const fetchUsers = async () => {
        try {
            const response = await getWxChatsApi(
                String(currentWxAccount.wx_id),
                true,
            );

            if (response.status === 200 || response.status === "success") {
                const users = response.data;
                setChatList(users);

                // 比较新获取的users长度与当前chatList长度
                if (users.length !== chatList.length) {
                    // 长度不一致时调用getWxUsersApi
                    await getWxUsersApi(String(currentWxAccount.wx_id), true);
                }
                return response.data;
            }
            return [];
        } catch (error) {
            console.error("API Error:", error);
            return [];
        } finally {
            // setLoading(true); // 结束加载状态
        }
    };

    // 获取用户状态
    const fetchUsersStatus = async (chatList, currentWxUser, setManualList) => {
        try {
            // 计算今天凌晨0点的时间
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // 筛选今天有更新的用户
            const todayActiveUsers = chatList.filter(user =>
                user.updated_at && new Date(user.updated_at) >= today
            );

            // 使用 Map 存储消息获取的 Promise，避免重复获取
            const messagePromises = new Map();

            // 为每个用户创建获取消息的 Promise
            todayActiveUsers.forEach(user => {
                const messageCount = (currentWxUser && user.thread_id === currentWxUser.thread_id) ? 10 : 10;
                messagePromises.set(user.thread_id, getMessageList(user.thread_id, messageCount));
            });

            // 并发执行所有 Promise
            const results = await Promise.all([...messagePromises.values()]);

            const newLastTimestamp = {};
            const newManualList = [];
            const unReadMessageCount = [];

            // 处理结果
            todayActiveUsers.forEach((user, index) => {
                const result = results[index];
                if (!result?.data) return;

                // 更新未读消息计数
                unReadMessageCount.push(findFirstReadTimeMessage(result.data));

                // 检查手动回复状态
                const {message} = findManualReplyMessage(result.data);
                if (message) {
                    newManualList.push({
                        threadId: user.thread_id,
                        nickname: user.nickname,
                        message
                    });
                }

                // 更新最后消息时间戳
                if (result.data[0]?.created_at) {
                    newLastTimestamp[user.thread_id] = result.data[0].created_at;
                }
            });

            // 批量更新状态
            setManualList(newManualList);
            wxUserUnreadList.current = unReadMessageCount;
            setTimestampList(newLastTimestamp);

            return {
                timestamps: newLastTimestamp,
                unreadCounts: unReadMessageCount,
                manualList: newManualList
            };
        } catch (error) {
            console.error("Error in fetchUsersStatus:", error);
            return null;
        }
    };


    const handleItemClick = async (item, mode) => {
        if (mode === "filter") {
            setSearchTerm(item.nickname);
        } else {
            setSearchTerm("");
            await localforage.setItem(
                `${currentWxAccount.wx_id}-currentWxUser`,
                item,
            );
        }
        setCurrentAutoReply(item.auto_reply);
        setCurrentWxUser(item);
        setThreadId(item.thread_id);
    };

    // 筛选符合搜索条件的 chatList
    const filteredChatList = chatList.filter((item) =>
        item.nickname.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    // 创建下拉菜单项
    const dropList = (
        <Menu style={{width: 280}}>
            {filteredChatList.map((item) => (
                <Menu.Item
                    key={item.thread_id}
                    onClick={() => handleItemClick(item, "filter")} // 点击时更新当前用户
                >
                    {item.nickname}
                </Menu.Item>
            ))}
        </Menu>
    );

    const formatMessageTime = (dateStr) => {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        // 转换为当地时间
        const time = date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        // 如果是今天
        if (date >= today) {
            return time;
        }
        // 如果是昨天
        else if (date >= yesterday) {
            return '昨天';
        }
        // 更早的日期
        else {
            return date.toLocaleDateString('zh-CN', {
                month: '2-digit',
                day: '2-digit'
            });
        }
    };

    return (
        <Space align={"start"}>
            <Space direction={"vertical"}>
                <Space style={{width: 300}}>
                    <Dropdown droplist={dropList} position="bl" trigger="click">
                        <InputSearch
                            allowClear
                            placeholder="搜索聊天"
                            value={searchTerm}
                            onChange={(value) => setSearchTerm(value)}
                            style={{width: 265}}
                        />
                    </Dropdown>
                    <Dropdown
                        droplist={
                            <Menu style={{width: 100}}>
                                <Menu.Item key="1" onClick={() => setManageVisible(true)}>
                                    管理回复
                                </Menu.Item>
                                <Menu.Item key="2" onClick={() => setNotificationVisible(true)}>
                                    配置通知
                                </Menu.Item>
                            </Menu>
                        }
                        position="bl"
                        trigger="click"
                    >
                        <Button type="primary" icon={<IconPlus/>}/>
                    </Dropdown>
                </Space>
                {/*聊天列表*/}
                <List
                    style={{
                        width: 305,
                        height: "88vh",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                    dataSource={sortedChatList}
                    render={(item, index) => (
                        <List.Item
                            key={item.thread_id}
                            style={{
                                backgroundColor:
                                    item.thread_id === currentWxUser?.thread_id
                                        ? "#366EF415"
                                        : "",
                                cursor: "pointer",
                                position: "relative",
                            }}
                        >
                            <div
                                key={`dot-${item.thread_id}`}  // 添加 key
                                style={{
                                    width: "9px",
                                    height: "9px",
                                    borderRadius: "50%",
                                    backgroundColor: manualList.some(
                                        (manual) => manual.threadId === item.thread_id,
                                    )
                                        ? "#F53F3F"
                                        : item.auto_reply
                                            ? "#00B42A"
                                            : "#FF7D00",
                                    position: "absolute",
                                    left: 4,
                                    top: 4,
                                    zIndex: 1,
                                }}
                            />
                            <List.Item.Meta
                                onClick={() => handleItemClick(item)}
                                avatar={
                                    <Badge
                                        key={`badge-${item.thread_id}`}  // 添加 key
                                        count={sortedUnreadList[index]}
                                        style={{marginTop: 2, marginLeft: 4}}
                                    >
                                        <Avatar
                                            shape="square"
                                            style={{backgroundColor: "#3370ff"}}
                                            triggerIcon={
                                                manualList.some(
                                                    (manual) => manual.threadId === item.thread_id,
                                                ) ? (
                                                    <Tag
                                                        color="red"
                                                        size="small"
                                                        style={{
                                                            width: "52px",
                                                            fontSize: "11px",
                                                            marginLeft: "-28px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        需人工
                                                    </Tag>
                                                ) : (
                                                    ""
                                                )
                                            }
                                        >
                                            {item.head_url ? (
                                                <img
                                                    alt="头像"
                                                    // src={`data:image/png;base64,${item.head_url}`}
                                                    src={`${item.head_url}`}
                                                />
                                            ) : (
                                                item.nickname.charAt(0)
                                            )}
                                        </Avatar>
                                    </Badge>
                                }
                                title={
                                    <Space align={"center"}>
                                        <Typography.Ellipsis style={{width: 128}}>
                                            {item.nickname}
                                        </Typography.Ellipsis>
                                        <Space direction={"vertical"} align={"end"}>
                                            <div
                                                style={{
                                                    color: "#86909C",
                                                    fontSize: "13px",
                                                    width: 50,
                                                    textAlign: "right",
                                                }}
                                            >
                                                {formatMessageTime(item.updated_at)}
                                            </div>
                                        </Space>
                                    </Space>
                                }
                                description={
                                    <Typography.Ellipsis style={{width: 190}}>
                                        {item.last_message ? item.last_message : '暂无新消息'}
                                    </Typography.Ellipsis>
                                    // <Typography.Ellipsis style={{ width: 190 }}>
                                    //   {/*{lastMessages[item.thread_id]}*/}
                                    // </Typography.Ellipsis>
                                }
                            />
                        </List.Item>
                    )}
                />

            </Space>
            <UserManagement
                visible={manageVisible}
                setVisible={setManageVisible}
                wxId={currentWxAccount.wx_id}
                onAutoReplyChange={(updatedUser) => {
                    // 更新chatList中对应用户的auto_reply状态
                    setChatList((prev) =>
                        prev.map((user) =>
                            user.to_user_id === updatedUser.to_user_id
                                ? {...user, auto_reply: updatedUser.auto_reply}
                                : user,
                        ),
                    );
                }}
            />
            <NotificationSettingModal
                visible={notificationVisible}
                setVisible={setNotificationVisible}
                wxId={currentWxAccount.wx_id}
            />
            <UserChat
                currentWxUser={currentWxUser}
                currentWxMyAccount={currentWxAccount}
                // imageUrls={imageUrls}
                // setImageUrls={setImageUrls}
                getMessageList={getMessageList}
                assistant={assistant}
                autoReply={currentAutoReply}
                setAutoReply={setCurrentAutoReply}
                manualMessage={manualList.find(
                    (manual) => manual.threadId === currentWxUser?.thread_id,
                )}
            />
        </Space>
    );
};

export default ChatList;
