// UserChat.jsx
import React, { useEffect, useState, useRef } from "react";
// import localforage from "localforage";
import {
  Input,
  Button,
  Tooltip,
  Space,
  Upload,
  Avatar,
  Empty,
  Tag,
  Typography,
  Spin,
  Switch,
  Message,
  Image,
} from "@arco-design/web-react";
import { IconFolderAdd, IconSend } from "@arco-design/web-react/icon";
import {
  getGroupMemberHeadApi,
  sendMessageApi,
  setAutoReplyForUser,
} from "../../../api/normalApi";
import {
  modifyMessageReadApi,
  getPictureContentApi,
  chatAgainApi,
} from "../../../api/openaiAPI";
import { formatTimestampToDateTime } from "../../../utils/format";
import localforage from "localforage";
import axios from "axios";

const { Text } = Typography;

const UserChat = ({
  currentWxUser,
  currentWxMyAccount,
  // imageUrls,
  getMessageList,
  assistant,
  autoReply,
  setAutoReply,
  manualMessage,
}) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const contentRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [messageList, setMessageList] = useState([]); //展示当前的消息列表（增加了头像）
  const [messageImages, setMessageImages] = useState({});
  const [isAIChatting, setIsAIChatting] = useState(false);
  const isFetchingMessages = useRef(false);
  const [messageAvatars, setMessageAvatars] = useState({}); // 新增: 存储消息头像的映射

  useEffect(() => {
    // console.log(messageAvatars);
  }, [messageAvatars]);

  // 加载图片
  useEffect(() => {
    const loadImages = async () => {
      const newImages = {};
      for (const message of messages) {
        for (const content of message.content) {
          if (content.type === "image_file" && content.image_file?.file_id) {
            if (!messageImages[content.image_file.file_id]) {
              const imageUrl = await getPictureById(content.image_file.file_id);
              if (imageUrl) {
                newImages[content.image_file.file_id] = imageUrl;
              }
            }
          }
        }
      }
      if (Object.keys(newImages).length > 0) {
        setMessageImages((prev) => ({ ...prev, ...newImages }));
      }
    };

    loadImages();
    // eslint-disable-next-line
  }, [messages]);

  // 加载图片
  useEffect(() => {
    return () => {
      Object.values(messageImages).forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [messageImages]);

  // 处理滚动
  useEffect(() => {
    // 添加滚动事件监听
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }

    // 清理事件监听
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && !isUserScrolling) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    if (messages && messages.length > 0) {
      modifyMessageRead();
    }

    if (messages.length !== messageList.length) {
      // 当前消息有更新
      setMessageList(messages);
      // console.log(messages)
      setIsUserScrolling(false);
    }
    // eslint-disable-next-line
  }, [messages, isUserScrolling]);

  // 加载聊天
  useEffect(() => {
    setMessages([]);
    const fetchMessages = async () => {
      if (currentWxUser) {
        const messages = await getMessageList(currentWxUser.thread_id, 50);
        if (messages && messages.data) {
          setMessages(messages.data);
        }

        const newAvatars = await handleGroupAvatars(
          messages.data,
          currentWxMyAccount,
          currentWxUser,
          messageAvatars
        );
        setMessageAvatars(newAvatars);
      }
    };

    fetchMessages().then((r) => {});
    // eslint-disable-next-line
  }, [currentWxUser]);

  // 每 5 秒获取当前用户的消息
  useEffect(() => {
    if (currentWxUser) {
      const fetchMessagesWithCheck = async () => {
        if (isFetchingMessages.current) {
          return;
        }

        try {
          isFetchingMessages.current = true;
          // 对当前选中的用户，总是获取50条消息
          const data = await getMessageList(currentWxUser.thread_id, 50);
          if (data && data.data) {
            // 比较最新消息是否发生变化
            const latestCurrentMessageId = messages[0]?.id;
            const latestNewMessageId = data.data[0]?.id;

            if (latestCurrentMessageId !== latestNewMessageId) {
              setMessages(data.data);
            }
          }
        } finally {
          isFetchingMessages.current = false;
        }
      };

      const messageIntervalId = setInterval(fetchMessagesWithCheck, 5000);

      return () => clearInterval(messageIntervalId);
    }
    // eslint-disable-next-line
  }, [currentWxUser]);

  // 处理群聊成员头像的函数
  const handleGroupAvatars = async (
    messages,
    currentWxAccount,
    currentWxUser,
    messageAvatars
  ) => {
    try {
      // 创建新的头像映射对象
      const newMessageAvatars = { ...messageAvatars };

      // 遍历所有消息
      for (const message of messages) {
        const actualUserId = message.metadata?.actual_user_id;

        // 检查是否需要获取新的头像
        if (actualUserId && !newMessageAvatars[actualUserId]) {
          // 构建缓存键
          const cacheKey = `avatar-${currentWxAccount.wx_id}-${currentWxUser.to_wx_id}-${actualUserId}`;

          try {
            // 首先尝试从缓存获取
            const cachedAvatar = await localforage.getItem(cacheKey);
            if (cachedAvatar) {
              newMessageAvatars[actualUserId] = cachedAvatar;
              // console.log('从缓存中获取头像:', cachedAvatar);
              continue;
            }

            // 如果缓存中没有，则从API获取
            const response = await getGroupMemberHeadApi(
              currentWxAccount.wx_id,
              currentWxUser.to_wx_id,
              actualUserId
            );

            // 检查API响应
            if (response.data?.head_url) {
              // 获取头像图片数据
              const imageResponse = await axios.get(response.data.head_url, {
                responseType: "blob",
              });
              const imageBlob = imageResponse.data;

              // 保存到缓存
              await localforage.setItem(cacheKey, imageBlob);

              // 更新头像映射
              newMessageAvatars[actualUserId] = imageBlob;
            }
          } catch (error) {
            console.error("获取头像失败:", error);
          }
        }
      }

      return newMessageAvatars;
    } catch (error) {
      console.error("处理群聊头像时出错:", error);
      return messageAvatars; // 发生错误时返回原始头像映射
    }
  };

  // 滚动事件处理
  const handleScroll = () => {
    setIsUserScrolling(true);
  };

  const modifyMessageRead = async () => {
    const latestMessage = messages.find(
      (item) => item.thread_id === currentWxUser.thread_id
    );
    if (!latestMessage) return;

    const data = {
      metadata: {
        readTime: String(Math.floor(Date.now() / 1000)),
      },
    };
    try {
      await modifyMessageReadApi(
        latestMessage.thread_id,
        latestMessage.id,
        data
      );
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const modifyMessageManual = async () => {
    // 找出所有需要人工处理的消息
    const manualMessages = messages.filter(
      (msg) => msg.metadata?.manual === "true"
    );

    const data = {
      metadata: {
        manual: "false",
      },
    };

    try {
      // 使用Promise.all同时处理所有消息的更新
      const updatePromises = manualMessages.map((msg) =>
        modifyMessageReadApi(msg.thread_id, msg.id, data)
      );

      await Promise.all(updatePromises);

      Message.info("已发送解除人工通知，等待安全校验后自动解除");

      // 更新完成后刷新消息列表
      if (getMessageList) {
        const updatedMessages = await getMessageList(currentWxUser.thread_id);
        setMessages(updatedMessages.data);
      }
    } catch (error) {
      console.error("Error updating manual messages:", error);
    }
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    const back_data = {
      wx_id: currentWxMyAccount.wx_id,
      to_user_id: currentWxUser.to_user_id, // Removed `.current`
      msg: inputValue,
    };
    setInputValue("");
    try {
      const response = await sendMessageApi(back_data);
      if (response.status === 200) {
        getMessageList(currentWxUser.thread_id).then((data) => {
          setMessages(data.data);
        });
      }
      if (manualMessage) {
        modifyMessageManual(manualMessage.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleAutoReplyToggle = (checked) => {
    const back_data = {
      wx_user_id: currentWxMyAccount.wx_id,
      nickname: currentWxUser.nickname,
      auto_reply: checked ? "true" : "false",
    };

    setAutoReplyForUser(back_data).then((res) => {
      if (res.status === 200) {
        setAutoReply(checked);
      }
    });
  };

  const chatAIAgain = async () => {
    setIsAIChatting(true);
    await chatAgainApi(currentWxUser.thread_id, assistant.id).then((res) => {
      setIsAIChatting(false);
      if (res.result === true) {
        setInputValue(res.reply);
      } else {
        Message.info("AI建议不回复用户");
      }
    });
  };

  const getPictureById = async (fileId) => {
    try {
      const blob = await getPictureContentApi(fileId);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  const renderMessageContent = (msg, item, contentIndex) => {
    if (item.type === "text" && item.text && item.text.value) {
      // 用户消息直接显示，不分割
      if (msg.role === "user") {
        return (
          <Space key={`${msg.id}-${contentIndex}`} align="center">
            <Text
              style={{
                color: "#000000",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                display: "inline-block",
                width: "auto",
              }}
            >
              {item.text.value}
            </Text>
          </Space>
        );
      }

      // 检查消息是否包含链接
      const hasLink = /(https?:\/\/[^\s]+)/.test(item.text.value);

      // 如果是销售消息且包含链接，不分割
      if (msg.role === "assistant" && hasLink) {
        return (
          <Space key={`${msg.id}-${contentIndex}`} align="center">
            <Text
              style={{
                color: "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#366EF4",
                display: "inline-block",
                width: "auto",
              }}
            >
              {item.text.value}
            </Text>
          </Space>
        );
      }

      // 其他销售消息保持原有的分割逻辑
      return (
        <React.Fragment key={`${msg.id}-${contentIndex}`}>
          {item.text.value
            .split(/(?<=[？?!。])/)
            .map((sentence, sentenceIndex) => (
              <Space
                key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                align="center"
              >
                <Text
                  style={{
                    color: "#ffffff",
                    padding: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#366EF4",
                    display: "inline-block",
                    width: "auto",
                  }}
                >
                  {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                </Text>
              </Space>
            ))}
        </React.Fragment>
      );
    } else if (item.type === "image_file" && item.image_file?.file_id) {
      const imageUrl = messageImages[item.image_file.file_id];
      return imageUrl ? (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Image
            src={imageUrl}
            alt="消息图片"
            width={250}
            style={{
              height: "auto",
              borderRadius: "8px",
              margin: "5px 0",
            }}
          />
        </Space>
      ) : (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Spin dot />
        </Space>
      );
    }
    return null;
  };

  // 原用户头像组件
  // const UserAvatar = ({ msg, index, currentWxUser, messageList, messages }) => {
  //   return (
  //       <Avatar shape="square" style={{ backgroundColor: "#3370ff" }} size={33}>
  //         {currentWxUser.is_group ? (
  //                 <img
  //                     alt="avatar"
  //                     // src={`${messageList[messages.length - 1 - index].head_url}`}
  //                     src={`${getGroupMemberHead(msg.metadata.actual_user_id)}`}
  //                 />
  //             // // 群聊用户头像
  //             // messageList[messages.length - 1 - index]?.head_img ? (
  //             //     <img
  //             //         alt="avatar"
  //             //         src={`${messageList[messages.length - 1 - index].head_url}`}
  //             //     />
  //             // ) : messageList[messages.length - 1 - index]?.nickname ? (
  //             //     messageList[messages.length - 1 - index].nickname.charAt(0)
  //             // ) : (
  //             //     currentWxUser.nickname.charAt(0)
  //             // )
  //         ) : (
  //             // 私聊用户头像
  //             currentWxUser.head_url ? (
  //                 <img
  //                     alt="avatar"
  //                     src={currentWxUser.head_url}
  //                 />
  //             ) : (
  //                 currentWxUser.nickname.charAt(0)
  //             )
  //         )}
  //       </Avatar>
  //   );
  // };

  // 用户头像组件
  // const UserAvatar = ({ msg }) => {
  //   console.log(messageAvatars);
  //   return (
  //     <Avatar shape="square" style={{ backgroundColor: "#3370ff" }} size={33}>
  //       {currentWxUser.is_group ? (
  //         msg.metadata?.actual_user_id &&
  //         messageAvatars[msg.metadata.actual_user_id] ? (
  //           <img
  //             alt="avatar"
  //             // 确保这里传递的是一个有效的 Blob 或 File 对象
  //             src={
  //               messageAvatars[msg.metadata.actual_user_id] instanceof Blob
  //                 ? URL.createObjectURL(
  //                     messageAvatars[msg.metadata.actual_user_id]
  //                   )
  //                 : messageAvatars[msg.metadata.actual_user_id]
  //             }
  //           />
  //         ) : (
  //           msg.metadata?.actual_nickname?.charAt(0) ||
  //           currentWxUser.nickname.charAt(0)
  //         )
  //       ) : currentWxUser.head_url ? (
  //         <img
  //           alt="avatar"
  //           src={currentWxUser.head_url}
  //           onError={(e) => {
  //             e.target.style.display = "none";
  //           }}
  //         />
  //       ) : (
  //         currentWxUser.nickname.charAt(0)
  //       )}
  //     </Avatar>
  //   );
  // };

  // 助手头像组件
  const AssistantAvatar = ({ currentWxMyAccount }) => {
    return (
      <Avatar shape="square" style={{ backgroundColor: "#3370ff" }} size={33}>
        {currentWxMyAccount.head_url ? (
          <img alt="avatar" src={currentWxMyAccount.head_url} />
        ) : (
          currentWxMyAccount.nickname.charAt(0)
        )}
      </Avatar>
    );
  };

  // 没有选中用户时的提示
  if (!currentWxUser) {
    return (
      <Space
        direction="vertical"
        style={{
          height: "92vh",
          boxShadow: "0px 0 6px rgba(0, 0, 0, 0.08)",
          marginLeft: 5,
        }}
        align={"start"}
      >
        <Empty
          description="请选择一个用户进行聊天"
          style={{ marginTop: "40.8vh", width: "39.5vw" }}
        />
      </Space>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{
        height: "92vh",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        marginLeft: 5,
      }}
      align={"start"}
    >
      <Space
        direction="horizontal"
        style={{
          width: "38vw",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <Avatar
            shape="square"
            style={{ backgroundColor: "#3370ff" }}
            size={33}
          >
            {currentWxUser.head_url ? (
              <img alt="avatar" src={currentWxUser.head_url} />
            ) : (
              currentWxUser.nickname.charAt(0)
            )}
          </Avatar>
          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
            {currentWxUser.nickname || ""}
          </h3>
          {/*<Divider type='vertical'/>*/}
          <Switch
            // type={"round"}
            checked={autoReply}
            onChange={handleAutoReplyToggle}
            checkedText="状态-自动回复"
            uncheckedText="状态-人工回复"
          />
          {manualMessage ? (
            <Button
              type="primary"
              status="danger"
              size="mini"
              shape="round"
              onClick={() => modifyMessageManual(manualMessage.message)}
            >
              解除警告
            </Button>
          ) : (
            ""
          )}
        </Space>

        <Space>
          {assistant !== null && assistant.name ? (
            <Avatar
              shape="square"
              style={{ backgroundColor: "#3370ff" }}
              size={33}
            >
              {currentWxMyAccount.head_url ? (
                <img alt="avatar" src={`${currentWxMyAccount.head_url}`} />
              ) : (
                currentWxMyAccount.nickname.charAt(0)
              )}
            </Avatar>
          ) : (
            ""
          )}
          <h3 style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
            {(currentWxMyAccount !== null && currentWxMyAccount.nickname) ||
              "暂未分配数字员工"}
          </h3>
          <Tag
            color={
              assistant !== null && assistant.name
                ? autoReply
                  ? "green"
                  : "orange"
                : "red"
            }
          >
            {assistant !== null && assistant.name
              ? autoReply
                ? "正在服务"
                : "观望不回"
              : "账号离线"}
          </Tag>
        </Space>
      </Space>
      <Space
        direction="vertical"
        style={{
          background: "#f7f7fa",
          height: "77vh",
          width: "38vw",
          overflowY: "auto",
          scrollbarWidth: "thin",
          paddingLeft: 15,
          paddingTop: 10,
          paddingBottom: 10,
          scrollbarColor: "#EEE white",
          boxShadow: "0 2px 2px rgba(0, 0, 0, 0.03)",
        }}
        ref={contentRef}
        onScroll={handleScroll}
      >
        {messages.length === 0 ? (
          <Space
            align="center"
            style={{ width: "40vw", height: "65vh", justifyContent: "center" }}
            direction={"vertical"}
          >
            <Spin dot />
          </Space>
        ) : (
          messages
            .slice()
            .reverse()
            .map((msg, index) => {
              return (
                <Space key={msg.id || index} align={"start"}>
                  {msg.role === "user" ? (
                    // <UserAvatar msg={msg} currentWxUser={currentWxUser} />
                    <Avatar
                      shape="square"
                      style={{ backgroundColor: "#3370ff" }}
                      size={33}
                    >
                      {currentWxUser.is_group ? (
                        msg.metadata?.actual_user_id &&
                        messageAvatars[msg.metadata.actual_user_id] ? (
                          <img
                            alt="avatar"
                            // 确保这里传递的是一个有效的 Blob 或 File 对象
                            src={
                              messageAvatars[
                                msg.metadata.actual_user_id
                              ] instanceof Blob
                                ? URL.createObjectURL(
                                    messageAvatars[msg.metadata.actual_user_id]
                                  )
                                : messageAvatars[msg.metadata.actual_user_id]
                            }
                          />
                        ) : (
                          msg.metadata?.actual_nickname?.charAt(0) ||
                          currentWxUser.nickname.charAt(0)
                        )
                      ) : currentWxUser.head_url ? (
                        <img
                          alt="avatar"
                          src={currentWxUser.head_url}
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      ) : (
                        currentWxUser.nickname.charAt(0)
                      )}
                    </Avatar>
                  ) : (
                    <AssistantAvatar currentWxMyAccount={currentWxMyAccount} />
                  )}
                  <Space direction={"vertical"}>
                    <Space direction="horizontal">
                      <Text>
                        {msg.role === "user"
                          ? currentWxUser.is_group
                            ? messageList[messages.length - 1 - index]
                              ? msg.metadata.actual_nickname
                              : ""
                            : currentWxUser.nickname
                          : currentWxMyAccount.nickname}
                      </Text>
                      <Text type="secondary" style={{ fontSize: "12px" }}>
                        {formatTimestampToDateTime(msg.created_at)}
                      </Text>
                      {msg.role === "user" ? (
                        <Tag color={"green"} style={{ color: "#00B42A" }}>
                          客户
                        </Tag>
                      ) : (
                        <Tag color={"orange"} style={{ color: "#FF7D00" }}>
                          销售
                        </Tag>
                      )}
                      {msg.metadata?.manual === "true" ? (
                        <Tag color={"red"} style={{ color: "#FF0000" }}>
                          触发人工
                        </Tag>
                      ) : msg.metadata?.manual === "false" ? (
                        <Tag color={"green"} style={{ color: "#00B42A" }}>
                          人工解除
                        </Tag>
                      ) : (
                        ""
                      )}
                    </Space>
                    <Space direction={"vertical"}>
                      {msg.content.map((item, contentIndex) =>
                        renderMessageContent(msg, item, contentIndex)
                      )}
                    </Space>
                  </Space>
                </Space>
              );
            })
        )}
      </Space>
      <Space style={{ marginTop: 10, marginLeft: "1.5vw" }}>
        <Tooltip content="选择文件发送">
          <Upload
            action={null}
            showUploadList={false}
            // fileList={pic ? [pic] : []}
            multiple={false}
            // onChange={(_, currentFile) => handleUploadPic(_, currentFile)}
            accept=".png,.jpeg,.jpg"
          >
            <IconFolderAdd style={{ width: "20px", height: "20px" }} />
          </Upload>
        </Tooltip>
        <Input
          value={inputValue}
          suffix={
            !autoReply ? (
              isAIChatting ? (
                <Spin dot />
              ) : (
                <Button type="text" onClick={chatAIAgain}>
                  AI生成
                </Button>
              )
            ) : (
              <></>
            )
          }
          onChange={(value) => setInputValue(value)}
          onPressEnter={handleSend}
          style={{
            borderRadius: "3vh",
            height: "5vh",
            width: "31.5vw",
            marginLeft: "0.5vw",
            marginRight: "0.5vw",
          }}
        />
        <Tooltip content="发送消息">
          <Button
            type="text"
            icon={<IconSend style={{ width: "20px", height: "20px" }} />}
            onClick={handleSend}
            disabled={inputValue.trim() === ""}
          />
        </Tooltip>
      </Space>
    </Space>
  );
};

export default UserChat;
