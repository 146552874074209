/* eslint-disable no-loop-func */
import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Input,
  Avatar,
  Space,
  Button,
  Tooltip,
  Spin,
} from "@arco-design/web-react";
import { IconSend } from "@arco-design/web-react/icon";
import {
  getWeChats,
  getWxChatsApi,
  createConversationApi,
  createMessageApi,
  getMessagesByConversationApi,
  getConversationsApi,
} from "../../api/normalApi";
import OpenAI from "openai";
import ReactMarkdown from "react-markdown";
import DataStatistics from "./DataStatistics";

// 在组件顶部添加存储键常量
const STORAGE_KEY = "monitorChatPreferences";

const MonitorChat = () => {
  const [conversationList, setConversationList] = useState([]);
  const [weChats, setWeChats] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatBackgrounds, setChatBackgrounds] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [chatType, setChatType] = useState("私聊");
  const [isLoading, setIsLoading] = useState(false); // 新增loading状态
  const [statCategories, setStatCategories] = useState([
    { title: "最近运营的怎么样" },
    { title: "顾客有哪些不满意的点?" },
    { title: "对于聊天数据有什么建议?" },
  ]);

  const conversationId = useRef(null);

  const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
  });

  // 修改 savePreferencesToLocal 函数
  const savePreferencesToLocal = (users, type) => {
    // 只有当用户数组非空时才保存
    if (users && users.length > 0) {
      const preferences = {
        selectedUsers: users,
        chatType: type,
      };
      // console.log('保存选择到本地:', preferences);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(preferences));
    }
  };

  const getConversations = async () => {
    try {
      const response = await getConversationsApi();
      if (
        response.status === "success" &&
        response.data &&
        response.data.length > 0
      ) {
        setConversationList(response.data);
        // 获取第一个会话的消息
        const firstConversation = response.data[0];
        conversationId.current = firstConversation.conversation_id;
        await getMessageByConversationId(firstConversation.conversation_id);
      }
    } catch (error) {
      console.error("获取会话列表失败:", error);
    }
  };

  useEffect(() => {
    getConversations();
  }, []);

  //  从本地存储中加载用户选择
  useEffect(() => {
    // 获取微信列表
    getWeChats().then((response) => {
      const availableWeChats = response.data;
      setWeChats(availableWeChats);

      // 只保留用户选择相关的本地存储
      const savedPreferences = localStorage.getItem(STORAGE_KEY);

      if (savedPreferences) {
        try {
          const { selectedUsers, chatType } = JSON.parse(savedPreferences);

          // 验证保存的用户是否仍然存在于可用列表中
          if (selectedUsers && selectedUsers.length > 0) {
            const validUsers = selectedUsers.filter((saved) =>
              availableWeChats.some(
                (available) => available.wx_id === saved.wx_id,
              ),
            );
            if (validUsers.length > 0) {
              // console.log('恢复保存的用户选择:', validUsers);
              setSelectedUsers(validUsers);
              setChatType(chatType || "私聊");
              return;
            }
          }
        } catch (error) {
          console.error("解析本地存储数据失败:", error);
        }
      }

      // 如果没有有效的保存数据，设置在线用户为默认值
      const onlineUsers = availableWeChats.filter(
        (chat) => chat.online === true,
      );
      // console.log('在线用户:', onlineUsers);

      if (onlineUsers.length > 0) {
        setSelectedUsers(onlineUsers);
        savePreferencesToLocal(onlineUsers, "私聊");
      } else {
        //如果用户列表大于0，选择第一个用户
        if (availableWeChats.length > 0) {
          setSelectedUsers([availableWeChats[0]]);
          savePreferencesToLocal([availableWeChats[0]], "私聊");
        }
      }
    });
  }, []); // 仅在组件挂载时执行一次

  useEffect(() => {
    setChatBackgrounds([]);

    savePreferencesToLocal(selectedUsers, chatType);
    if (selectedUsers.length >= 1) {
      // 2. 创建一个临时数组来存储新的背景数据
      let newBackgrounds = [];

      // 3. 使用 Promise.all 来处理所有请求
      Promise.all(
        selectedUsers.map((chat) =>
          getWxChatsApi(String(chat.wx_id), true).then((response) => {
            let chats = [];
            if (chatType === "私聊") {
              chats = response.data.filter((chat) => !chat.is_group);
            }
            if (chatType === "群聊") {
              chats = response.data.filter((chat) => chat.is_group);
            }
            if (chatType === "全部") {
              chats = response.data;
            }

            const Backgrounds = chats
              .filter((chat) => chat.background)
              .map((chat) => ({
                chat_name: chat.nickname,
                summary: chat.background,
                is_group: chat.is_group,
              }));

            if (Backgrounds.length > 0) {
              const userBackground = {
                user_nickname: chat.nickname,
                chat_list: Backgrounds,
              };
              newBackgrounds.push(userBackground);
            }
          }),
        ),
      ).then(() => {
        // 4. 一次性更新所有背景数据
        setChatBackgrounds(newBackgrounds);
      });
    }
  }, [selectedUsers, chatType]);

  const createConversation = async (title) => {
    const conversation = await createConversationApi({
      title,
    });

    console.log("conversation:", conversation);
    conversationId.current = conversation.data.conversation_id;
  };

  const createMessage = async (role, content) => {
    await createMessageApi({
      conversation_id: conversationId.current,
      role,
      content,
    });
  };

  const getMessageByConversationId = async (conversationId) => {
    try {
      const response = await getMessagesByConversationApi(conversationId);
      if (response.status === "success" && response.data) {
        // 将API返回的消息格式转换为应用所需的格式
        const formattedMessages = response.data
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)) // 确保消息按时间顺序排列
          .map((msg) => ({
            role: msg.role,
            content: msg.content,
          }));
        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error("获取消息失败:", error);
      setMessages([]); // 发生错误时清空消息
    }
  };

  const handleSend = async (value) => {
    try {
      setIsLoading(true);
      const newUserMessage = {
        role: "user",
        content: inputValue || value,
      };

      // 不再使用本地存储，直接更新UI
      setMessages((prev) => [...prev, newUserMessage]);

      const date = new Date().toLocaleDateString();
      const systemContent = `
                今天日期是${date}
                你的任务是：作为数据分析助手，结合用户提出的问题，针对下面的对话进行统计分析，并结构化输出统计结果,
                聊天记录中user_nickname是AI接管的微信号名称，chat_name是那个微信号下每个客户/群聊的昵称，chat_list是聊天的信息总结，is_group是是否是群聊
                注意：你输出结果的时候，必要时要引用数据涉及到的user_nickname以及chat_name来达到统计可溯源的效果，但输出不要带user_nickname的字样
                ${JSON.stringify(chatBackgrounds, null, 2)}
            `;

      if (!conversationId.current) {
        await createConversation(inputValue || value);
      }
      await createMessage("user", inputValue || value);

      setInputValue("");

      let systemMessage = {
        role: "system",
        content: systemContent,
      };

      const completion = await openai.chat.completions.create({
        model: "gpt-4o",
        messages: [systemMessage, ...messages, newUserMessage],
        stream: true,
      });

      let currentResponse = "";
      for await (const chunk of completion) {
        const content = chunk.choices[0]?.delta?.content || "";
        if (content) {
          currentResponse += content;
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage?.role === "assistant") {
              newMessages[newMessages.length - 1] = {
                role: "assistant",
                content: currentResponse,
              };
            } else {
              newMessages.push({
                role: "assistant",
                content: currentResponse,
              });
            }
            return newMessages;
          });
        }
      }

      await createMessage("assistant", currentResponse);
      // 获取最新的消息列表
      await getMessageByConversationId(conversationId.current);
    } catch (error) {
      console.error("API 调用错误:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "抱歉，处理您的请求时出现了错误。请稍后重试。",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const newMessages = () => {
    setMessages([]);
    // 如果需要，可以调用后端API来清空会话
    conversationId.current = null;
  };

  return (
    <Space>
      <Space
        direction={"vertical"}
        style={{
          height: "100vh",
          width: "40vw",
        }}
      >
        <Space style={{ marginTop: 12 }}>
          <Select
            style={{ minWidth: 200 }}
            mode="multiple"
            value={selectedUsers.map((user) => user.wx_id)} // 修改为使用 wx_id
            onChange={(values) => {
              const selectedUserObjects = values
                .map((wx_id) => weChats.find((chat) => chat.wx_id === wx_id))
                .filter(Boolean); // 过滤掉未定义的值

              if (selectedUserObjects.length > 0) {
                setSelectedUsers(selectedUserObjects);
                savePreferencesToLocal(selectedUserObjects, chatType);
              }
            }}
            placeholder="请选择用户"
          >
            {weChats.map((chat) => (
              <Select.Option key={chat.wx_id} value={chat.wx_id}>
                <Space>
                  <Avatar
                    shape="square"
                    size={20}
                    style={{ backgroundColor: "#3370ff", marginBottom: 1 }}
                  >
                    {chat.head_url ? (
                      <img alt="头像" src={`${chat.head_url}`} />
                    ) : (
                      chat.nickname.charAt(0)
                    )}
                  </Avatar>
                  {chat.nickname}
                </Space>
              </Select.Option>
            ))}
          </Select>
          <Select
            value={chatType}
            onChange={(value) => setChatType(value)}
            style={{ width: 80 }}
          >
            <Select.Option value="群聊">群聊</Select.Option>
            <Select.Option value="私聊">私聊</Select.Option>
            <Select.Option value="全部">全部</Select.Option>
          </Select>
        </Space>

        {messages.length > 0 ? (
          <Space direction={"vertical"}>
            <Space
              direction={"vertical"}
              style={{
                height: "85vh",
                width: "40vw",
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "#EEE white",
              }}
            >
              {messages.map((message, index) => (
                <Space
                  key={index}
                  style={{ marginTop: 10, maxWidth: "40vw", marginRight: 10 }}
                  align={"start"}
                >
                  <Avatar
                    size={35}
                    style={{
                      backgroundColor:
                        message.role === "assistant" ? "#FF7D00" : "#165DFF",
                    }}
                  >
                    {message.role === "assistant" ? "AI" : "我"}
                  </Avatar>

                  {message.role === "user" ? (
                    <div
                      style={{
                        padding: 8,
                        backgroundColor: "#165DFF",
                        color: "#FFF",
                        borderRadius: "8px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {message.content}
                    </div>
                  ) : (
                    <Space
                      style={{
                        marginTop: -6,
                        background: "#F7F7FA",
                        padding: 8,
                        borderRadius: 10,
                      }}
                    >
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    </Space>
                  )}
                </Space>
              ))}
            </Space>
            <Space>
              <Tooltip content="清空对话记录">
                <Button
                  type="text"
                  shape="circle"
                  onClick={newMessages}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                    >
                      <path d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z" />
                    </svg>
                  }
                  style={{ marginTop: 10 }}
                />
              </Tooltip>
              <Input
                value={inputValue}
                onChange={(value) => setInputValue(value)}
                onPressEnter={isLoading ? "" : handleSend}
                style={{
                  borderRadius: "3vh",
                  width: "calc(40vw - 100px)",
                  marginTop: 10,
                  height: 50,
                }}
                placeholder="输入要对相关帐号提问的内容"
              />
              {isLoading ? (
                <Spin size={16} style={{ marginTop: 8, marginLeft: 10 }} />
              ) : (
                <Button
                  type="text"
                  disabled={
                    isLoading ||
                    inputValue.trim() === "" ||
                    selectedUsers.length === 0
                  } // 添加isLoading条件
                  onClick={handleSend}
                  icon={
                    <IconSend
                      style={{
                        width: "20",
                        height: "20",
                        marginLeft: 10,
                        marginTop: 3,
                      }}
                    />
                  }
                  style={{ marginTop: 10 }}
                ></Button>
              )}
            </Space>
          </Space>
        ) : (
          <Space
            align={"center"}
            direction={"vertical"}
            style={{ width: "40vw", marginTop: "30vh" }}
          >
            <h1>想统计点什么?</h1>
            <Space>
              <Input
                value={inputValue}
                onChange={(value) => setInputValue(value)}
                onPressEnter={handleSend}
                style={{
                  borderRadius: "3vh",
                  width: "calc(40vw - 100px)",
                  marginTop: 10,
                  height: 50,
                }}
                placeholder="请输入要统计的内容"
              />
            </Space>
            <Space style={{ marginTop: "15px" }}>
              {statCategories.map((category, index) => (
                <Button
                  key={index}
                  status="warning"
                  disabled={isLoading || selectedUsers.length === 0} // 添加isLoading条件
                  onClick={(v) => {
                    handleSend(category.title);
                  }}
                >
                  {category.title}
                </Button>
              ))}
            </Space>
          </Space>
        )}
      </Space>
      <DataStatistics chatBackgrounds={chatBackgrounds} messages={messages} />
    </Space>
  );
};

export default MonitorChat;
